.items__item-container {
  border: 1px solid #eee;
  margin: 0.5rem 0;
  // padding: 1rem;
  padding: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  min-height: 120px;
  cursor: pointer;
}

.items__item-container-half-height {
  border: 1px solid #eee;
  margin: 0.5rem 0;
  // padding: 1rem;
  padding: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  min-height: 60px;
  cursor: pointer;
}

.items__item-container--deactivated {
  background: #f4f4f5;
  align-items: flex-start;
  padding: 1.25rem;
}

.items__price {
  color: #34a853;
  margin-left: auto;
}

.item-checkbox {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  margin: 0 1rem;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.item-checkbox--deactivated {
  margin: auto 1rem;
}

.item-checkbox--checked {
  position: relative;

  background-color: var(--p-blue);
  border: 1px solid var(--p-blue);
}

.item-checkbox--checked::before {
  content: " ";
  position: absolute;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-image: url("../../assets/icons/checkbox.svg");
}

.menu-categories {
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 50px;
}

.menu-item__tag {
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
}

.deactivate__actions {
  width: 90%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
}

.menu__deactivate-modal {
  width: 100%;
  left: 0;
}

.deactivate-mobile {
  position: fixed;
  bottom: 0px;
  margin-bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
}

@media only screen and (max-width: 800px) {
  .menu-categories {
    width: 100%;
    align-items: center;
    margin-top: 2rem;
  }

  .menu-items__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .items__item-container--deactivated {
    // flex-direction: column;
    align-items: initial;
  }
}

.items__empty-deactivated {
  max-width: 48px;
  max-height: 48px;
}

.modifier-parents{
  text-align:left;

}

.modifier-parent{
  font-size:16px;
}

.search-wrapper{
  display: block;
  border-radius: 6px;
  border: 1px solid #a8a8a8;
  width:100%;
}

.search-wrapper-inner{
  display: inline-block;
  width: 100%;
  text-align: left;
  background: rgb(255, 255, 255);
  border-radius: 6px;
}

.search-input{
  padding: 10px 15px;
  border-radius: 15px;
  width: calc(100% - 40px);
  display: inline-block;
  height: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0px;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden;
}

.search-input:focus{
  outline:0px;
}

.search-icon{
  position: relative;
  height: 30px;
  top: 10px;
}
