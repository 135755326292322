.Footer {
  position: fixed;
  display: flex;
  flex-direction: row;
  left: 8px;
  bottom: 8px;
  width: calc(100vw - 16px);
  z-index: 10;
  text-align: center;
  margin: auto;
  justify-content: space-around;
  border-radius: 4px;
  box-shadow: 0px -4px 16px -2px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  background: var(--p-blue);
}
