.tabs-container {
  position: relative;
}

.tabs-container::after {
  content: "";
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5984768907563025) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  width: 50px;
  height: 50px;
  position: fixed;
  right: 0;
}

.hours__current-day::before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: var(--p-pink);
  border-radius: 50%;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
}

.hours-day {
  width: 150px;
}

.bar-chart-row {
  margin-bottom: 20px;
}

$day_height: 50px;
$day_width: 30px;

.bar-chart-day {
  width: 110px;
  display: inline-block;
  vertical-align: middle;
  line-height: $day_height;
  font-weight: 900;
}

.bar-chart-hours {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.bar-chart-hour {
  width: $day_width;
  height: $day_height;
  background-color: #f3f6f8;
  border-left: 1px solid #00155d;
  border-right: 1px solid #00155d;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
}

.bar-chart-hour-header {
  display: inline-block;
  width: $day_width;
  font-size: 9px;
  text-align: left;
  position: relative;
  left: 3px;
}

.bar-chart-bar {
  position: absolute;
  height: $day_height;
  background-color: #00155d;
  text-align: center;
}

.bar-chart-bar-duration {
  color: white;
  display: inline-block;
  vertical-align: middle;
  line-height: $day_height;
}

.no-right-border {
  border-right: 0px !important;
}

.hours-dropdown-item {
  white-space: nowrap;
  width: 170px;
}

.hours-actions {
  flex-basis: 230px;
  display: flex;
  flex-shrink: 1;
  padding: 1.5rem;
  border-radius: 8px;
  border: 3px solid var(--p-blue);
}

.hours-input__special-container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 2 1 0;
}

.prep-time-row {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.prep-time-row div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.prep-time-label {
  margin-right: 15px;
}

.prep-time-value {
  padding: 0.5rem 2rem;
  margin: 0 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
}

@media only screen and (max-width: 800px) {
  .hours-input__special-container {
    margin-right: 0;
  }
  .hours-actions {
    width: 100%;
    margin-bottom: 1rem;
  }

  .prep-time-row {
    flex-direction: column;
    align-items: flex-start;
  }

  .prep-actions {
    margin: 1rem auto 0 auto;
  }

  .hours-dropdown-item {
    width: 120px;
  }

  .special-hours__row > div {
    margin-right: 0;
    margin-top: 1rem;
  }
}

.react-datepicker__input-container input {
  border: 1px solid #eee !important;
  border-radius: 8px !important;
  min-width: 100% !important;
}

.react-datepicker__time-container {
  width: 130px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 130px !important;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  height: 45px !important;
  padding: 5px 10px !important;
  font-size: 18px !important;
  white-space: nowrap !important;
}

.react-datepicker__header--time {
  display: none !important;
}

.react-datepicker__time-list-item,
.react-datepicker__month-container {
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol" !important;
}
