.Card{
  position: relative;
  display: inline-block;
  width: calc(100% - 32px);
  min-width: 220px;
  margin: 16px 2%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  overflow: hidden;
  transition: all .2s ease;
  vertical-align: top;
  transition: all .2s;
  cursor: pointer;
}

.Card:hover{
  transform: scale(1.01);
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.6);
}