.Integrations {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-bottom: 32px;
}

.Integration {
  width: calc(100% - 32px);
  padding: 12px;
  border-bottom: 1px solid #aaa;
  display: flex;
  overflow: hidden;
  transition: all 0.4s;
}

.IntegrationImg {
  height: 72px;
  margin-right: 16px;
  border-radius: 8px;
}

.IntegrationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
}

.IntegrationButton {
  margin-left: auto;
  /* outline: none; */
}

.IntegrationNewImg {
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}

.IntegrationNewImg:hover {
  color: #222;
  font-weight: 900;
}

.IntegrationNewImg:hover img {
  transition: all 0.2s;
  transform: scale(1.05);
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 800px) {
  .Integrations {
    padding-top: 32px;
  }

  .Integration {
    flex-direction: column;
    align-items: center;
  }
  .IntegrationImg {
    width: 64px;
    height: auto;
    margin: 0;
  }

  .IntegrationContent {
    margin: 0;
    align-items: center;
  }

  .IntegrationButton {
    margin: 12px 0px;
  }
}
