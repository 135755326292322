.Accounting{
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.PaymentsRow{
  transition: all .2s;
}

/* .PaymentsRow:nth-child(odd){
  background: #f6f6f6;
} */

.PaymentsRow:hover{
  background: rgba(66, 132, 244, .2);
  box-shadow: 0px 4px 8px -2px rgba(0,0,0,.2);
  transform: scale(1.01);
  cursor: pointer;
}