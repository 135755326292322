.NumberFlipper{
  position: relative;
  display: flex;
  justify-content: center;
}

.Number{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 1px;
  font-size: 32px;
  font-weight: 900;
  border-radius: 4px;
  padding: 5px;
  color: rgba(255,255,255,.01);
  background: rgb(255,255,255);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,.2);
  animation: slide-in .6s forwards ease-in;
  -webkit-animation: slide-in-safari .6s forwards ease-in;
}

.Flipper{
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  background:rgb(59, 134, 255, .1);
  animation: flip-down .6s forwards ease-in;
}

.Punctuation{
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  color: #fff;
  margin: 12px 1px; 
  font-size: 28px;
  font-weight: 900;
}

@media only screen and (max-width: 800px){
  .Number{
    font-size: 24px;
  }
  .Punctuation{
    margin: 8px 1px; 
  }
}

@keyframes slide-in{
  0%{
    -webkit-text-fill-color : currentColor;
    color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color : currentColor;
    color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color : currentColor;
    color: inherit;
  }
}

@keyframes slide-in-safari{
  0%{
    -webkit-text-fill-color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color: inherit;
  }
}

@keyframes flip-down{
  0%{
    border-radius: 4px 4px 0px 0px;
    top: 0;
    height: 50%;
    transform: rotate(0deg);
  }
  80% {
    border-radius: 4px;
    top:50%;
    height: 10%;
    transform: rotate(-5deg);
  }
  100%{
    border-radius: 0px 0px 4px 4px;
    top: 50%;
    height: 50%;
    transform: rotate(0deg);
  }
}