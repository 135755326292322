@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap);
.text {
  font-size: 1rem;
}

.text--xs {
  font-size: 0.6rem;
}

.text--s {
  font-size: 0.75rem;
}

.text--m {
  font-size: 1rem;
}

.text--l {
  font-size: 1.25rem;
}

.text--xl {
  font-size: 1.5rem;
}

.text--header {
  font-size: 2rem;
}

.text--regular {
  font-weight: 400;
}

.text--bold {
  font-weight: 600;
}

.text--extrabold {
  font-weight: 700;
}

.text--black {
  font-weight: 900;
}

.text--color-blue {
  -webkit-text-fill-color: #01035e;
  color: #01035e;
}

.text--color-grey {
  -webkit-text-fill-color: #888888;
  color: #888888;
}

.text--color-white {
  -webkit-text-fill-color: white;
  color: white;
}

.text--color-black {
  -webkit-text-fill-color: #000000;
  color: #000000;
}

.text--color-pink {
  -webkit-text-fill-color: #d62e70;
  color: #d62e70;
}

.text--left {
  text-align: left;
}

.text--center {
  text-align: center;
}

.text--right {
  text-align: right;
}

.a-button {
  border: none;
  cursor: pointer; }

.button--blue {
  background-color: var(--p-blue);
  border: 2px solid var(--p-blue); }

.button--pink {
  background-color: var(--p-pink);
  border: 2px solid var(--p-pink); }

.button--white {
  background-color: white;
  border: 2px solid white; }

.button--transparent {
  background: transparent; }

.button--no-radius {
  border-radius: 0px; }

.button--primary {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 900;
  font-size: 1.25rem;
  color: white;
  -webkit-text-fill-color: white; }
  .button--primary > div {
    color: white;
    -webkit-text-fill-color: white; }
  .button--primary.button--blue:not(.button--transparent):not(.button--disabled):hover {
    background-color: transparent;
    color: var(--p-blue);
    -webkit-text-fill-color: var(--p-blue);
    box-shadow: none; }
    .button--primary.button--blue:not(.button--transparent):not(.button--disabled):hover > div {
      color: var(--p-blue);
      -webkit-text-fill-color: var(--p-blue); }
  .button--primary.button--pink:not(.button--transparent):not(.button--disabled):hover {
    background-color: transparent;
    color: var(--p-pink);
    -webkit-text-fill-color: var(--p-pink);
    box-shadow: none; }
    .button--primary.button--pink:not(.button--transparent):not(.button--disabled):hover > div {
      color: var(--p-pink);
      -webkit-text-fill-color: var(--p-pink); }
  .button--primary .button--white:not(.button--transparent):not(.button--disabled):hover {
    color: var(--p-blue);
    -webkit-text-fill-color: var(--p-blue);
    background-color: white; }

.button--primary-inverted {
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-weight: 900;
  background-color: transparent;
  font-size: 1.25rem;
  color: var(--p-blue);
  -webkit-text-fill-color: var(--p-blue); }
  .button--primary-inverted.button--blue:not(.button--transparent):not(.button--disabled):hover {
    background-color: var(--p-blue);
    color: white;
    -webkit-text-fill-color: white;
    box-shadow: none; }
  .button--primary-inverted.button--white {
    color: white;
    -webkit-text-fill-color: white; }
    .button--primary-inverted.button--white:not(.button--transparent):not(.button--disabled):hover {
      background-color: white;
      color: var(--p-blue);
      -webkit-text-fill-color: var(--p-blue); }

.button--secondary {
  border: none;
  background-color: transparent;
  font-weight: 900;
  font-size: 1.25rem;
  color: var(--p-blue);
  -webkit-text-fill-color: var(--p-blue); }
  .button--secondary.button--blue:hover {
    border-bottom: 2px solid var(--p-blue); }
  .button--secondary.button--pink {
    color: var(--p-pink);
    -webkit-text-fill-color: var(--p-pink); }
    .button--secondary.button--pink:hover {
      border-bottom: 2px solid var(--p-pink); }

.button--disabled {
  cursor: not-allowed;
  background: var(--p-grey);
  box-shadow: none;
  color: white;
  -webkit-text-fill-color: white;
  border: none; }

.toggle-label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px; }

.toggle-label > input {
  opacity: 0;
  width: 0;
  height: 0; }

.toggle-switch {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  border-radius: 100px;
  transition: 200ms linear;
  border: 1px solid var(--p-blue); }

.toggle-switch--checked {
  background-color: var(--p-blue); }

.toggle-switch::before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  background-color: var(--p-blue);
  transition: 200ms linear;
  -webkit-transition: 200ms linear; }

.toggle-switch--checked::before {
  background-color: white;
  -webkit-transform: translateX(50%);
          transform: translateX(50%); }

.button-wrapper__container {
  background: transparent;
  padding: 0;
  z-index: 1;
  cursor: pointer;
  border: none; }

.button-wrapper--with-border {
  border: inherit; }

.loadingPage{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    padding: 24px;
  }
  .lds-roller div {
    -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    -webkit-transform-origin: 32px 32px;
            transform-origin: 32px 32px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #4284f4;
    margin: -3px 0 0 -3px;
  }
  .lds-roller div:nth-child(1) {
    -webkit-animation-delay: -0.036s;
            animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
  }
  .lds-roller div:nth-child(2) {
    -webkit-animation-delay: -0.072s;
            animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
  }
  .lds-roller div:nth-child(3) {
    -webkit-animation-delay: -0.108s;
            animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
  }
  .lds-roller div:nth-child(4) {
    -webkit-animation-delay: -0.144s;
            animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
  }
  .lds-roller div:nth-child(5) {
    -webkit-animation-delay: -0.18s;
            animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
  }
  .lds-roller div:nth-child(6) {
    -webkit-animation-delay: -0.216s;
            animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
  }
  .lds-roller div:nth-child(7) {
    -webkit-animation-delay: -0.252s;
            animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
  }
  .lds-roller div:nth-child(8) {
    -webkit-animation-delay: -0.288s;
            animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
  }
  @-webkit-keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes lds-roller {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  
.tab-button {
  border-radius: 6px; }

.tab-button:focus,
.tab-button:hover {
  outline: none;
  background-color: rgba(214, 46, 112, 0.04); }

.tab {
  position: relative; }

.tab::after {
  content: " ";
  width: 0;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  display: block;
  transition: -webkit-transform 0.1s;
  transition: transform 0.1s;
  transition: transform 0.1s, -webkit-transform 0.1s;
  height: 2px;
  background-color: var(--p-pink);
  bottom: 0;
  will-change: transform;
  left: 0;
  position: absolute; }

.tab--active.tab::after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1); }

.mobile-tab {
  margin-right: 13px;
  font-size: 20px; }

.tabs-container {
  flex-wrap: wrap;
  width: 100%; }

.input--default {
  background: initial;
  width: 100%;
  transition: initial;
  margin: initial;
  padding: initial;
  font-size: 16px;
  max-width: initial;
  border-radius: 8px;
  padding: 0.5rem;
  border: 1px solid #eee; }

.input--default:focus {
  border-bottom: initial; }

.input--error {
  border: 1px solid #ff0000;
  background-color: #ffcccc; }

.input__container {
  position: relative; }

.input__container--error::before {
  content: attr(data-error-message);
  color: red;
  position: absolute;
  top: -20px; }

.Footer {
  position: fixed;
  display: flex;
  flex-direction: row;
  left: 8px;
  bottom: 8px;
  width: calc(100vw - 16px);
  z-index: 10;
  text-align: center;
  margin: auto;
  justify-content: space-around;
  border-radius: 4px;
  box-shadow: 0px -4px 16px -2px rgba(0, 0, 0, 0.6);
  overflow: hidden;
  background: var(--p-blue);
}

.Sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 270px;
  height: 100%;
  background: var(--p-blue);

  text-align: center;
  z-index: 13;
  overflow-y: auto;
}

.SidebarContent {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
  flex-direction: column;
  margin: 0 1.5rem;
}

.SidebarMobile {
  position: fixed;
  top: 8px;
  left: 8px;
  width: calc(100vw - 16px);
  background: #fff;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.4);
  text-align: center;
  background: var(--p-blue);
}

.SidebarContainerMobile {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.25rem;
}

.SidebarHNGR {
  font-size: 48px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.SidebarHNGRMobile {
  font-size: 22px;
  padding: 8px;
  font-weight: 900;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline-block;
  vertical-align: top;
}

.SidebarLogo {
  width: 48%;
  max-width: 250px;
  margin: 16px;
}

.SidebarLogoMobile {
  height: 32px;
  margin: 8px;
  vertical-align: top;
  border-radius: 4px;
}

.SidebarPage {
  margin: 4px;
  font-size: 1.25rem;
  padding: 8px;
  text-align: left;
  /* width: 84%; */
  /* border-radius: 4px; */
  transition: all 0.2s ease;
  cursor: pointer;
  text-transform: capitalize;
}

.SidebarPageMobile {
  margin: 4px;
  font-size: 50px !important;
  padding: 8px;
  text-align: left;
  /* width: 84%; */
  /* border-radius: 4px; */
  transition: all 0.2s ease;
  cursor: pointer;
  text-transform: capitalize;

}

.SidebarPageActive {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.SidebarIcon {
  width: 32px;
}

.SidebarMenu {
  width: 32px;
}

.SidebarExpansion {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-animation: grow-in 0.2s forwards ease;
          animation: grow-in 0.2s forwards ease;
}

.SidebarSelectedRest {
  height: 16px;
  margin: 0px 8px;
  vertical-align: middle;
}

@-webkit-keyframes grow-in {
  from {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes grow-in {
  from {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    opacity: 1;
  }
}

.sidebar-mobile__center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.switch-modal{
  width: calc(100vw - 32px);
  max-width: 600px;
  min-height: 100px;
  max-height: 70vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  animation: slide-up .5s ease forwards;
  -webkit-animation: slide-up .5s ease forwards;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: scroll;
  text-align:center;
}

:root {
  --p-blue: #01035e;
  --p-pink: #d62e70;
  --p-black: #000000;
  --p-grey: #cccccc;
}

* {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
}
/* 
body,
input,
button {
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
} */

a {
  text-decoration: none;
}

input {
  background: 0;
  border: 0;
  font-size: 16px;
  border-bottom: 2px solid #ccc;
  transition: all 0.2s;
  width: calc(100% - 32px);
  margin: 8px;
  padding: 8px;
}

.no-ryan-input {
  background: initial;
  border: initial;
  border-bottom: initial;
  transition: initial;
  width: initial;
  margin: initial;
  padding: initial;
  font-size: 16px;
  max-width: initial;
  border-radius: initial;
}

input::-webkit-input-placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: #aaa;
}

input::placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: #aaa;
}

input:focus {
  border-bottom: 4px solid #4284f4;
  /* outline: none; */
}

select {
  /* outline: none; */
  font-size: 12px;
  font-weight: 500;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0;
  padding: 8px 12px;
}

textarea {
  background: #fff;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #222;
  padding: 16px;
  margin: 8px;
  width: calc(100% - 48px);
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
}

.button {
  background: #4284f4;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 12px 24px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

.button:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.2);
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  border-collapse: collapse;
  box-sizing: border-box;
}

tr {
  transition: all 0.2s;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e1e1;
  box-sizing: border-box;
}

tr:nth-child(even) {
  background: #fafafa;
}

tr:hover {
  background: rgba(66, 132, 244, 0.1);
  /* cursor: pointer; */
}

td {
  padding: 18px 24px;
}

thead {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

th {
  padding: 8px;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  /* font-family: "Nunito Sans", sans-serif; */
  background: #dee8f3;
}

.fluid-content {
  max-width: 1440px;
}

.fluid-content--body {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (min-width: 1441px) {
  .fluid-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.AppBody {
  position: relative;
  top: 0px;
  margin-left: auto;
  width: calc(100vw - 270px);
  padding: 24px;
  text-align: center;
}

.AppBodyMobile {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 60px 16px 84px 16px;
  text-align: center;
  z-index: 0;
  -webkit-animation: slide-in 0.5s forwards ease;
          animation: slide-in 0.5s forwards ease;
}

.inline-block {
  display: inline-block;
}

.secondary {
  background: rgb(0, 0, 0, 0);
  font-weight: 500;
  border: 3px solid #fff;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
}

.secondary:hover {
  box-shadow: none;
  -webkit-transform: none;
          transform: none;
  font-weight: 700;
  padding: 8px 10px;
}
.borderbox {
  box-sizing: border-box;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w96px {
  width: 96px;
}

.w64px {
  width: 64px;
}

.w32px {
  width: 32px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.p0 {
  padding: 0;
}

.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}

.pv4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ph8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ph16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pv16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ph32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pv32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.m0 {
  margin: 0;
}

.mh4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mh8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mv8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mh16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.fs21 {
  font-size: 21px;
}

.fs24 {
  font-size: 24px;
}

.fs28 {
  font-size: 28px;
}

.fs32 {
  font-size: 32px;
}

.fs48 {
  font-size: 48px;
}

.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw500 {
  font-weight: 500;
}

.fw700 {
  font-weight: 700;
}

.fw900 {
  font-weight: 900;
}

.c222 {
  color: #222;
  -webkit-text-fill-color: #222;
}

.c999 {
  color: #999;
  -webkit-text-fill-color: #999;
}

.cbbb {
  color: #bbb;
  -webkit-text-fill-color: #bbb;
}

.cfff {
  color: #ffffff;
  -webkit-text-fill-color: #fff;
}

.cblue {
  color: #4284f4;
  -webkit-text-fill-color: #4284f4;
}

.bgfff {
  background: #fff;
}

.bgblue {
  background: #4284f4;
}

.cgreen {
  color: #34a853;
  -webkit-text-fill-color: #34a853;
}

.bggreen {
  background: #34a853;
}

.cred {
  color: #ea4335;
  -webkit-text-fill-color: #ea4335;
}

.bgred {
  background: #ea4335;
}

.textshadow {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
}

.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.pointer {
  cursor: pointer;
}

.vatop {
  vertical-align: top;
}

.vamiddle {
  vertical-align: middle;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100vw);
            transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.datepicker {
  /* font-family: "Nunito Sans", sans-serif; */
}

.input--b-grey,
.input--b--l-grey {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 0.5rem;
}

.input--b--l-grey {
  border: 1px solid #eee;
}

.input--b-grey::-webkit-input-placeholder {
  font-size: 16px;
  color: #888888;
}

.input--b-grey::placeholder {
  font-size: 16px;
  color: #888888;
}

.input--b--l-grey::-webkit-input-placeholder {
  color: #d0d0d0;
  font-size: 16px;
}

.input--b--l-grey::placeholder {
  color: #d0d0d0;
  font-size: 16px;
}

.no-ryan-input:focus {
  border-bottom: initial;
}

.border-b-pink {
  border-bottom: 1px solid #d62e70;
  border-bottom: 1px solid var(--p-pink);
}

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4691f6;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}

.input-no-outline:focus{
    outline-width: 0;
}

.odot-outer-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
}

.odot-container{
  width: 500px;
  height: 85vh;
  overflow: hidden;
}

.odot{
  width:200%;
  height:200%;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ReactModal__Overlay--after-open{
  z-index:999;
}

.toast-container {
  position: fixed;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 13; }

@media only screen and (max-width: 800px) {
  .toast-container {
    margin-top: 3rem; } }

.a-toast {
  border-radius: 8px;
  padding: 1rem 1.5rem;
  margin: 1rem;
  color: black;
  font-weight: 600;
  background: white;
  box-shadow: 0px 4px 6px #0000001f, 0px 1px 12px 4px #0000000a;
  font-size: 1rem;
  z-index: 13; }

.a-toast--success {
  border-left: 9px solid #559040; }

.a-toast--error {
  border-left: 9px solid red; }

.Card{
  position: relative;
  display: inline-block;
  width: calc(100% - 32px);
  min-width: 220px;
  margin: 16px 2%;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  overflow: hidden;
  transition: all .2s ease;
  vertical-align: top;
  transition: all .2s;
  cursor: pointer;
}

.Card:hover{
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.6);
}
.Background {
  position: fixed;
  top: 0;
  left: 270px;
  width: calc(100% - 270px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.Modal {
  position: fixed;
  width: calc(100% - 32px);
  min-height: 100px;
  max-height: 90%;
  max-width: 600px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0, 0, 0, 0.4);
  z-index: 11;
  animation: slide-up 0.5s forwards ease;
  -webkit-animation: slide-up 0.5s forwards ease;
  overflow-x: hidden;
  overflow-y: scroll;
}

.ModalLarge {
  position: fixed;
  min-height: 100px;
  width: calc(100% - 320px);
  max-height: 90%;
  max-width: 1200px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0, 0, 0, 0.4);
  z-index: 11;
  animation: slide-up 0.5s forwards ease;
  -webkit-animation: slide-up 0.5s forwards ease;
  overflow-x: hidden;
  overflow-y: scroll;
}

.Close {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 20px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 12;
  border-radius: 50%;
  background: #fff;
  padding: 4px;
}

#Exit {
  animation: drop-out 0.5s forwards ease;
  -webkit-animation: drop-out 0.5s forwards ease;
}

@keyframes slide-up {
  0% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  60% {
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@-webkit-keyframes slide-up {
  0% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
  60% {
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
}

@keyframes drop-out {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  40% {
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}

@-webkit-keyframes drop-out {
  0% {
    -webkit-transform: translateY(0vh);
            transform: translateY(0vh);
  }
  40% {
    -webkit-transform: translateY(-6vh);
            transform: translateY(-6vh);
  }
  100% {
    -webkit-transform: translateY(100vh);
            transform: translateY(100vh);
  }
}

@media only screen and (max-width: 800px) {
  .Background {
    left: 0;
    width: 100vw;
  }
  .ModalLarge {
    width: calc(100% - 32px);
  }
}

.NewsCardImg{
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  cursor: pointer;
}

.iFrameButton{
  position: absolute;
  bottom: 24px;
  left: 50%;
  font-size: 14px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  box-shadow: 0px 8px 18px 0px rgba(0,0,0,.6);
  cursor: pointer;
}

.iFrameButton:hover{
  -webkit-transform: scale(1.01) translateX(-50%);
          transform: scale(1.01) translateX(-50%);
  box-shadow: 0px 8px 20px 0px rgba(0,0,0,.6);
}
.PostCardImg{
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  cursor: pointer;
}
.NumberFlipper{
  position: relative;
  display: flex;
  justify-content: center;
}

.Number{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 1px;
  font-size: 32px;
  font-weight: 900;
  border-radius: 4px;
  padding: 5px;
  color: rgba(255,255,255,.01);
  background: rgb(255,255,255);
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,.2);
  animation: slide-in .6s forwards ease-in;
  -webkit-animation: slide-in-safari .6s forwards ease-in;
}

.Flipper{
  position: absolute;
  top: 0;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  background:rgb(59, 134, 255, .1);
  -webkit-animation: flip-down .6s forwards ease-in;
          animation: flip-down .6s forwards ease-in;
}

.Punctuation{
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
  color: #fff;
  margin: 12px 1px; 
  font-size: 28px;
  font-weight: 900;
}

@media only screen and (max-width: 800px){
  .Number{
    font-size: 24px;
  }
  .Punctuation{
    margin: 8px 1px; 
  }
}

@-webkit-keyframes slide-in{
  0%{
    -webkit-text-fill-color : currentColor;
    color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color : currentColor;
    color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color : currentColor;
    color: inherit;
  }
}

@keyframes slide-in{
  0%{
    -webkit-text-fill-color : currentColor;
    color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color : currentColor;
    color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color : currentColor;
    color: inherit;
  }
}

@-webkit-keyframes slide-in-safari{
  0%{
    -webkit-text-fill-color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color: inherit;
  }
}

@keyframes slide-in-safari{
  0%{
    -webkit-text-fill-color : rgba(255,255,255,.01);
  }
  60% {
    -webkit-text-fill-color: rgba(255,255,255,.01);
  }
  100%{
    -webkit-text-fill-color: inherit;
  }
}

@-webkit-keyframes flip-down{
  0%{
    border-radius: 4px 4px 0px 0px;
    top: 0;
    height: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    border-radius: 4px;
    top:50%;
    height: 10%;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100%{
    border-radius: 0px 0px 4px 4px;
    top: 50%;
    height: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes flip-down{
  0%{
    border-radius: 4px 4px 0px 0px;
    top: 0;
    height: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  80% {
    border-radius: 4px;
    top:50%;
    height: 10%;
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg);
  }
  100%{
    border-radius: 0px 0px 4px 4px;
    top: 50%;
    height: 50%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.GrubcardHeader{
  display: flex;
  padding: 12px;
  padding-bottom: 0px;
  align-items: center;
}

.GrubcardLogo{
  width: 36px;
  margin-right: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,.2);
  display: inline-block;
}

.GrubcardCalendarImg{
  height: 18px;
  margin: 4px;
  vertical-align: -30%;
}

.GrubcardCaption{
  color: #fff;
  margin: 4px 0px 16px 0px;
}
.CustomerCardHeader{
  display: flex;
  padding: 12px;
  padding-bottom: 0px;
  align-items: center;
  color: #FFEA3B;
  -webkit-text-fill-color: #FFEA3B;
}

.CustomerCardLogo{
  width: 48px;
  margin-right: 12px;
  display: inline-block;
}

.CustomerCardCalendarImg{
  height: 18px;
  margin: 4px;
  vertical-align: -30%;
}

.CustomerCardCaption{
  color: #fff;
  margin: 4px 0px 16px 0px;
}

.CustomerCardCustomer{
  position: relative;
}

.CustomerCardCustomer > img {
  width: 32px;
}

.CustomerCardCustomerOrders{
  position: absolute;
  right: 16px;
  top: 16px;
}

.CustomerCardCustomerCaption{
  position: absolute;
  right: 0px;
  top: 36px;
}
.Feed{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
}

.FeedFooterImg{
  width: 96px;
  margin: auto;
  padding-top: 64px;
}
.Support{
  width: 100%;
  max-width: 400px;
  margin: auto;
}
.Integrations {
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-bottom: 32px;
}

.Integration {
  width: calc(100% - 32px);
  padding: 12px;
  border-bottom: 1px solid #aaa;
  display: flex;
  overflow: hidden;
  transition: all 0.4s;
}

.IntegrationImg {
  height: 72px;
  margin-right: 16px;
  border-radius: 8px;
}

.IntegrationContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
}

.IntegrationButton {
  margin-left: auto;
  /* outline: none; */
}

.IntegrationNewImg {
  position: relative;
  display: inline-block;
  color: rgba(0, 0, 0, 0);
  transition: all 0.2s;
}

.IntegrationNewImg:hover {
  color: #222;
  font-weight: 900;
}

.IntegrationNewImg:hover img {
  transition: all 0.2s;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.2);
}

@media only screen and (max-width: 800px) {
  .Integrations {
    padding-top: 32px;
  }

  .Integration {
    flex-direction: column;
    align-items: center;
  }
  .IntegrationImg {
    width: 64px;
    height: auto;
    margin: 0;
  }

  .IntegrationContent {
    margin: 0;
    align-items: center;
  }

  .IntegrationButton {
    margin: 12px 0px;
  }
}

html {
  /* font-family: "Nunito Sans", sans-serif; */
}

.Login {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("/hngr_background.png") no-repeat center center fixed;
  background-size: cover;
}

input {
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  width: calc(100% - 48px);
  max-width: 300px;
  margin: 4px;
}

.login-button {
  margin-top: 16px;
  max-height: 48px;
  padding: 12px 24px;
  transition: all 0.2s;
}

.LoginGoogle {
  margin: 24px;
  /* font-family: "Nunito Sans", sans-serif !important; */
  background: #4284f4 !important;
  padding: 4px !important;
  margin: 4px !important;
  border: 0 !important;
  border-radius: 4px !important;
}

.LoginGoogle:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
}

.LoginGoogle div {
  border-radius: 4px !important;
  padding: 6px !important;
  height: 18px !important;
  width: 18px !important;
  margin: 0px 4px !important;
}

.LoginGoogle span {
  font-weight: 700 !important;
  margin: 0px 4px !important;
}

.Login h3 {
  color: 'white';
  font-weight: 'bold';
}


.tabs-container {
  position: relative; }

.tabs-container::after {
  content: "";
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.598477) 0%, white 100%);
  width: 50px;
  height: 50px;
  position: fixed;
  right: 0; }

.hours__current-day::before {
  content: " ";
  width: 10px;
  height: 10px;
  position: absolute;
  background-color: var(--p-pink);
  border-radius: 50%;
  left: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.hours-day {
  width: 150px; }

.bar-chart-row {
  margin-bottom: 20px; }

.bar-chart-day {
  width: 110px;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
  font-weight: 900; }

.bar-chart-hours {
  display: inline-block;
  vertical-align: top;
  position: relative; }

.bar-chart-hour {
  width: 30px;
  height: 50px;
  background-color: #f3f6f8;
  border-left: 1px solid #00155d;
  border-right: 1px solid #00155d;
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box; }

.bar-chart-hour-header {
  display: inline-block;
  width: 30px;
  font-size: 9px;
  text-align: left;
  position: relative;
  left: 3px; }

.bar-chart-bar {
  position: absolute;
  height: 50px;
  background-color: #00155d;
  text-align: center; }

.bar-chart-bar-duration {
  color: white;
  display: inline-block;
  vertical-align: middle;
  line-height: 50px; }

.no-right-border {
  border-right: 0px !important; }

.hours-dropdown-item {
  white-space: nowrap;
  width: 170px; }

.hours-actions {
  flex-basis: 230px;
  display: flex;
  flex-shrink: 1;
  padding: 1.5rem;
  border-radius: 8px;
  border: 3px solid var(--p-blue); }

.hours-input__special-container {
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  flex: 2 1; }

.prep-time-row {
  margin-top: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.prep-time-row div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }

.prep-time-label {
  margin-right: 15px; }

.prep-time-value {
  padding: 0.5rem 2rem;
  margin: 0 1rem;
  border: 1px solid #eee;
  border-radius: 8px; }

@media only screen and (max-width: 800px) {
  .hours-input__special-container {
    margin-right: 0; }
  .hours-actions {
    width: 100%;
    margin-bottom: 1rem; }
  .prep-time-row {
    flex-direction: column;
    align-items: flex-start; }
  .prep-actions {
    margin: 1rem auto 0 auto; }
  .hours-dropdown-item {
    width: 120px; }
  .special-hours__row > div {
    margin-right: 0;
    margin-top: 1rem; } }

.react-datepicker__input-container input {
  border: 1px solid #eee !important;
  border-radius: 8px !important;
  min-width: 100% !important; }

.react-datepicker__time-container {
  width: 130px !important; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box {
  width: 130px !important; }

.react-datepicker__time-container
.react-datepicker__time
.react-datepicker__time-box
ul.react-datepicker__time-list
li.react-datepicker__time-list-item {
  height: 45px !important;
  padding: 5px 10px !important;
  font-size: 18px !important;
  white-space: nowrap !important; }

.react-datepicker__header--time {
  display: none !important; }

.react-datepicker__time-list-item,
.react-datepicker__month-container {
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important; }

.items__item-container {
  border: 1px solid #eee;
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  min-height: 120px;
  cursor: pointer; }

.items__item-container-half-height {
  border: 1px solid #eee;
  margin: 0.5rem 0;
  padding: 0;
  display: flex;
  background-color: white;
  align-items: center;
  width: 100%;
  overflow-y: hidden;
  min-height: 60px;
  cursor: pointer; }

.items__item-container--deactivated {
  background: #f4f4f5;
  align-items: flex-start;
  padding: 1.25rem; }

.items__price {
  color: #34a853;
  margin-left: auto; }

.item-checkbox {
  height: 20px;
  width: 20px;
  min-width: 20px;
  min-height: 20px;
  margin: 0 1rem;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 3px; }

.item-checkbox--deactivated {
  margin: auto 1rem; }

.item-checkbox--checked {
  position: relative;
  background-color: var(--p-blue);
  border: 1px solid var(--p-blue); }

.item-checkbox--checked::before {
  content: " ";
  position: absolute;
  background-repeat: no-repeat;
  width: 10px;
  height: 10px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-position: center;
  background-image: url(/static/media/checkbox.9bc879ed.svg); }

.menu-categories {
  position: -webkit-sticky;
  position: sticky;
  display: flex;
  flex-direction: column;
  top: 50px; }

.menu-item__tag {
  border-radius: 1rem;
  padding: 0.25rem 0.5rem; }

.deactivate__actions {
  width: 90%;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end; }

.menu__deactivate-modal {
  width: 100%;
  left: 0; }

.deactivate-mobile {
  position: fixed;
  bottom: 0px;
  margin-bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 90%; }

@media only screen and (max-width: 800px) {
  .menu-categories {
    width: 100%;
    align-items: center;
    margin-top: 2rem; }
  .menu-items__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center; }
  .items__item-container--deactivated {
    align-items: initial; } }

.items__empty-deactivated {
  max-width: 48px;
  max-height: 48px; }

.modifier-parents {
  text-align: left; }

.modifier-parent {
  font-size: 16px; }

.search-wrapper {
  display: block;
  border-radius: 6px;
  border: 1px solid #a8a8a8;
  width: 100%; }

.search-wrapper-inner {
  display: inline-block;
  width: 100%;
  text-align: left;
  background: white;
  border-radius: 6px; }

.search-input {
  padding: 10px 15px;
  border-radius: 15px;
  width: calc(100% - 40px);
  display: inline-block;
  height: 45px;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0px;
  font-size: 16px;
  box-sizing: border-box;
  overflow: hidden; }

.search-input:focus {
  outline: 0px; }

.search-icon {
  position: relative;
  height: 30px;
  top: 10px; }

.chrome-picker {
  min-width: 100%;
  position: absolute;
  z-index: 10; }

.brand__colors {
  display: flex;
  margin-top: 1rem; }

.brand__color-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 1;
  flex-basis: 25%; }
  .brand__color-wrapper:not(:first-child) {
    margin: 0 2rem; }

.brand__color {
  width: 100%;
  height: 60px; }

.brand__color-pick {
  border: 2px dashed var(--p-blue);
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-top: 0.5rem;
  max-height: 70px; }

.preview__container {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto; }

.preview__desktop-container {
  position: relative;
  margin-right: 1rem; }

.preview__desktop-image {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 25px;
  right: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

@media only screen and (max-width: 800px) {
  .brand__colors {
    flex-basis: 100%;
    flex-direction: column; }
  .brand__color-wrapper:not(:first-child) {
    margin: 1rem 0; } }

.logo-wrapper {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  flex-basis: 20%;
  margin-top: 1rem; }
  .logo-wrapper:not(:first-child):not(:last-child) {
    margin-left: 2rem;
    margin-right: 2rem; }

.logos__container {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  margin-right: 1rem;
  flex-wrap: wrap; }

.logo__used-in {
  min-height: 30px; }

.logo__image {
  max-height: 200px;
  max-width: 200px; }

.logo__image-container {
  padding: 0.5rem;
  margin-top: 0.5rem;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center; }

.logo__image-container--with-image {
  background-color: #f2f2f2; }

.logo__upload-container {
  margin-top: 1rem;
  height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--p-blue); }

.logo__upload-container:hover,
.logo__upload-container--dragging {
  cursor: pointer;
  background: #f3f1fb; }

.logo__edit-modal-actions {
  width: 100%;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.logo__image-uploaded {
  margin-top: 1rem;
  min-height: 200px;
  background-color: #f2f2f2;
  padding: 1rem; }
  .logo__image-uploaded > img {
    max-height: 200px; }

@media only screen and (max-width: 800px) {
  .logos__container {
    flex-direction: column; }
  .logo-wrapper:not(:first-child) {
    margin-top: 2rem; } }

.basic-info__container {
  display: flex;
  flex-direction: column; }

.basic-info__row-field {
  display: flex;
  flex: 1 1;
  margin-right: 1rem; }

.basic-info__row-value {
  display: flex;
  flex: 4 0; }

.basic-info__row-value--active {
  display: flex;
  flex: 4 0; }

.TransfersBody:nth-child(odd){
  background: #fff;
}

.Transfers{
  width: 100%;
  overflow-x:auto;
}
.Accounting{
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.PaymentsRow{
  transition: all .2s;
}

/* .PaymentsRow:nth-child(odd){
  background: #f6f6f6;
} */

.PaymentsRow:hover{
  background: rgba(66, 132, 244, .2);
  box-shadow: 0px 4px 8px -2px rgba(0,0,0,.2);
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  cursor: pointer;
}
.bg-pink {
  background: var(--p-pink); }

.bg-blue {
  background: var(--p-blue); }

.flex {
  display: flex; }

.block {
  display: block; }

.flex-row {
  display: flex;
  flex-direction: row; }

.flex-align-end {
  align-items: flex-end; }

.flex-inline {
  display: inline-flex; }

.flex-col {
  display: flex;
  flex-direction: column; }

.flex-apart {
  justify-content: space-between; }

.flex-justify-center {
  justify-content: center; }

.flex-justify-start {
  justify-content: flex-start; }

.flex-justify-end {
  justify-content: flex-end; }

.flex-align-start {
  align-items: flex-start; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-grow {
  flex-grow: 1; }

.flex-evenly {
  justify-content: space-evenly; }

.flex-align-center {
  align-items: center; }

.flex-basis-50 {
  display: flex;
  flex-basis: 50%; }

.flex-basis-35 {
  display: flex;
  flex-basis: 35%; }

.flex-grow-1 {
  display: flex;
  flex: 1 1; }

.flex-grow-2 {
  display: flex;
  flex: 2 1; }

.align-self-start {
  display: flex;
  align-self: flex-start; }

.no-wrap {
  white-space: nowrap; }

.button-default {
  background: transparent;
  border: none;
  cursor: pointer; }

.br-b {
  border-bottom: 1px solid black; }

.border-2 {
  border-width: 2px; }

.border-1 {
  border-width: 1px; }

.br-blue {
  border-color: var(--p-blue); }

.br-grey {
  border-color: var(--p-grey); }

.br-pink {
  border-color: var(--p-pink); }

.rel {
  position: relative; }

.absolute {
  position: absolute; }

.ml-auto {
  margin-left: auto; }

.mh-auto {
  margin-left: auto;
  margin-right: auto; }

.mt-auto {
  margin-top: auto; }

.mr-auto {
  margin-right: auto; }

.x-auto {
  overflow-x: auto; }

.y-auto {
  overflow-y: auto; }

.m-0 {
  margin: 0rem; }

.mv-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.mh-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mt-0 {
  margin-top: 0rem; }

.mb-0 {
  margin-bottom: 0rem; }

.mr-0 {
  margin-right: 0rem; }

.ml-0 {
  margin-left: 0rem; }

.p-0 {
  padding: 0rem; }

.pv-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.ph-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.pt-0 {
  padding-top: 0rem; }

.pb-0 {
  padding-bottom: 0rem; }

.pr-0 {
  padding-right: 0rem; }

.pl-0 {
  padding-left: 0rem; }

.m-1 {
  margin: 0.25rem; }

.mv-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.mh-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mt-1 {
  margin-top: 0.25rem; }

.mb-1 {
  margin-bottom: 0.25rem; }

.mr-1 {
  margin-right: 0.25rem; }

.ml-1 {
  margin-left: 0.25rem; }

.p-1 {
  padding: 0.25rem; }

.pv-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.ph-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.pt-1 {
  padding-top: 0.25rem; }

.pb-1 {
  padding-bottom: 0.25rem; }

.pr-1 {
  padding-right: 0.25rem; }

.pl-1 {
  padding-left: 0.25rem; }

.m-2 {
  margin: 0.5rem; }

.mv-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.mh-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mt-2 {
  margin-top: 0.5rem; }

.mb-2 {
  margin-bottom: 0.5rem; }

.mr-2 {
  margin-right: 0.5rem; }

.ml-2 {
  margin-left: 0.5rem; }

.p-2 {
  padding: 0.5rem; }

.pv-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.ph-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.pt-2 {
  padding-top: 0.5rem; }

.pb-2 {
  padding-bottom: 0.5rem; }

.pr-2 {
  padding-right: 0.5rem; }

.pl-2 {
  padding-left: 0.5rem; }

.m-3 {
  margin: 0.75rem; }

.mv-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.mh-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mt-3 {
  margin-top: 0.75rem; }

.mb-3 {
  margin-bottom: 0.75rem; }

.mr-3 {
  margin-right: 0.75rem; }

.ml-3 {
  margin-left: 0.75rem; }

.p-3 {
  padding: 0.75rem; }

.pv-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.ph-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.pt-3 {
  padding-top: 0.75rem; }

.pb-3 {
  padding-bottom: 0.75rem; }

.pr-3 {
  padding-right: 0.75rem; }

.pl-3 {
  padding-left: 0.75rem; }

.m-4 {
  margin: 1rem; }

.mv-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.mh-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mt-4 {
  margin-top: 1rem; }

.mb-4 {
  margin-bottom: 1rem; }

.mr-4 {
  margin-right: 1rem; }

.ml-4 {
  margin-left: 1rem; }

.p-4 {
  padding: 1rem; }

.pv-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.ph-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.pt-4 {
  padding-top: 1rem; }

.pb-4 {
  padding-bottom: 1rem; }

.pr-4 {
  padding-right: 1rem; }

.pl-4 {
  padding-left: 1rem; }

.m-5 {
  margin: 1.25rem; }

.mv-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.mh-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mt-5 {
  margin-top: 1.25rem; }

.mb-5 {
  margin-bottom: 1.25rem; }

.mr-5 {
  margin-right: 1.25rem; }

.ml-5 {
  margin-left: 1.25rem; }

.p-5 {
  padding: 1.25rem; }

.pv-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.ph-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.pt-5 {
  padding-top: 1.25rem; }

.pb-5 {
  padding-bottom: 1.25rem; }

.pr-5 {
  padding-right: 1.25rem; }

.pl-5 {
  padding-left: 1.25rem; }

.m-6 {
  margin: 1.5rem; }

.mv-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.mh-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mt-6 {
  margin-top: 1.5rem; }

.mb-6 {
  margin-bottom: 1.5rem; }

.mr-6 {
  margin-right: 1.5rem; }

.ml-6 {
  margin-left: 1.5rem; }

.p-6 {
  padding: 1.5rem; }

.pv-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.ph-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.pt-6 {
  padding-top: 1.5rem; }

.pb-6 {
  padding-bottom: 1.5rem; }

.pr-6 {
  padding-right: 1.5rem; }

.pl-6 {
  padding-left: 1.5rem; }

.m-7 {
  margin: 1.75rem; }

.mv-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.mh-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.mt-7 {
  margin-top: 1.75rem; }

.mb-7 {
  margin-bottom: 1.75rem; }

.mr-7 {
  margin-right: 1.75rem; }

.ml-7 {
  margin-left: 1.75rem; }

.p-7 {
  padding: 1.75rem; }

.pv-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.ph-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.pt-7 {
  padding-top: 1.75rem; }

.pb-7 {
  padding-bottom: 1.75rem; }

.pr-7 {
  padding-right: 1.75rem; }

.pl-7 {
  padding-left: 1.75rem; }

.m-8 {
  margin: 2rem; }

.mv-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.mh-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mt-8 {
  margin-top: 2rem; }

.mb-8 {
  margin-bottom: 2rem; }

.mr-8 {
  margin-right: 2rem; }

.ml-8 {
  margin-left: 2rem; }

.p-8 {
  padding: 2rem; }

.pv-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.ph-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.pt-8 {
  padding-top: 2rem; }

.pb-8 {
  padding-bottom: 2rem; }

.pr-8 {
  padding-right: 2rem; }

.pl-8 {
  padding-left: 2rem; }

.m-9 {
  margin: 2.25rem; }

.mv-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.mh-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.mt-9 {
  margin-top: 2.25rem; }

.mb-9 {
  margin-bottom: 2.25rem; }

.mr-9 {
  margin-right: 2.25rem; }

.ml-9 {
  margin-left: 2.25rem; }

.p-9 {
  padding: 2.25rem; }

.pv-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.ph-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.pt-9 {
  padding-top: 2.25rem; }

.pb-9 {
  padding-bottom: 2.25rem; }

.pr-9 {
  padding-right: 2.25rem; }

.pl-9 {
  padding-left: 2.25rem; }

.m-10 {
  margin: 2.5rem; }

.mv-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.mh-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mt-10 {
  margin-top: 2.5rem; }

.mb-10 {
  margin-bottom: 2.5rem; }

.mr-10 {
  margin-right: 2.5rem; }

.ml-10 {
  margin-left: 2.5rem; }

.p-10 {
  padding: 2.5rem; }

.pv-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.ph-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.pt-10 {
  padding-top: 2.5rem; }

.pb-10 {
  padding-bottom: 2.5rem; }

.pr-10 {
  padding-right: 2.5rem; }

.pl-10 {
  padding-left: 2.5rem; }

.m-11 {
  margin: 2.75rem; }

.mv-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.mh-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.mt-11 {
  margin-top: 2.75rem; }

.mb-11 {
  margin-bottom: 2.75rem; }

.mr-11 {
  margin-right: 2.75rem; }

.ml-11 {
  margin-left: 2.75rem; }

.p-11 {
  padding: 2.75rem; }

.pv-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.ph-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.pt-11 {
  padding-top: 2.75rem; }

.pb-11 {
  padding-bottom: 2.75rem; }

.pr-11 {
  padding-right: 2.75rem; }

.pl-11 {
  padding-left: 2.75rem; }

.m-12 {
  margin: 3rem; }

.mv-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.mh-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mt-12 {
  margin-top: 3rem; }

.mb-12 {
  margin-bottom: 3rem; }

.mr-12 {
  margin-right: 3rem; }

.ml-12 {
  margin-left: 3rem; }

.p-12 {
  padding: 3rem; }

.pv-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.ph-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.pt-12 {
  padding-top: 3rem; }

.pb-12 {
  padding-bottom: 3rem; }

.pr-12 {
  padding-right: 3rem; }

.pl-12 {
  padding-left: 3rem; }

.m-13 {
  margin: 3.25rem; }

.mv-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.mh-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.mt-13 {
  margin-top: 3.25rem; }

.mb-13 {
  margin-bottom: 3.25rem; }

.mr-13 {
  margin-right: 3.25rem; }

.ml-13 {
  margin-left: 3.25rem; }

.p-13 {
  padding: 3.25rem; }

.pv-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.ph-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.pt-13 {
  padding-top: 3.25rem; }

.pb-13 {
  padding-bottom: 3.25rem; }

.pr-13 {
  padding-right: 3.25rem; }

.pl-13 {
  padding-left: 3.25rem; }

.m-14 {
  margin: 3.5rem; }

.mv-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.mh-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.mt-14 {
  margin-top: 3.5rem; }

.mb-14 {
  margin-bottom: 3.5rem; }

.mr-14 {
  margin-right: 3.5rem; }

.ml-14 {
  margin-left: 3.5rem; }

.p-14 {
  padding: 3.5rem; }

.pv-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.ph-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.pt-14 {
  padding-top: 3.5rem; }

.pb-14 {
  padding-bottom: 3.5rem; }

.pr-14 {
  padding-right: 3.5rem; }

.pl-14 {
  padding-left: 3.5rem; }

.m-15 {
  margin: 3.75rem; }

.mv-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.mh-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mt-15 {
  margin-top: 3.75rem; }

.mb-15 {
  margin-bottom: 3.75rem; }

.mr-15 {
  margin-right: 3.75rem; }

.ml-15 {
  margin-left: 3.75rem; }

.p-15 {
  padding: 3.75rem; }

.pv-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.ph-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.pt-15 {
  padding-top: 3.75rem; }

.pb-15 {
  padding-bottom: 3.75rem; }

.pr-15 {
  padding-right: 3.75rem; }

.pl-15 {
  padding-left: 3.75rem; }

.m-16 {
  margin: 4rem; }

.mv-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.mh-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.mt-16 {
  margin-top: 4rem; }

.mb-16 {
  margin-bottom: 4rem; }

.mr-16 {
  margin-right: 4rem; }

.ml-16 {
  margin-left: 4rem; }

.p-16 {
  padding: 4rem; }

.pv-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.ph-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.pt-16 {
  padding-top: 4rem; }

.pb-16 {
  padding-bottom: 4rem; }

.pr-16 {
  padding-right: 4rem; }

.pl-16 {
  padding-left: 4rem; }

.m-17 {
  margin: 4.25rem; }

.mv-17 {
  margin-top: 4.25rem;
  margin-bottom: 4.25rem; }

.mh-17 {
  margin-left: 4.25rem;
  margin-right: 4.25rem; }

.mt-17 {
  margin-top: 4.25rem; }

.mb-17 {
  margin-bottom: 4.25rem; }

.mr-17 {
  margin-right: 4.25rem; }

.ml-17 {
  margin-left: 4.25rem; }

.p-17 {
  padding: 4.25rem; }

.pv-17 {
  padding-top: 4.25rem;
  padding-bottom: 4.25rem; }

.ph-17 {
  padding-left: 4.25rem;
  padding-right: 4.25rem; }

.pt-17 {
  padding-top: 4.25rem; }

.pb-17 {
  padding-bottom: 4.25rem; }

.pr-17 {
  padding-right: 4.25rem; }

.pl-17 {
  padding-left: 4.25rem; }

.m-18 {
  margin: 4.5rem; }

.mv-18 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem; }

.mh-18 {
  margin-left: 4.5rem;
  margin-right: 4.5rem; }

.mt-18 {
  margin-top: 4.5rem; }

.mb-18 {
  margin-bottom: 4.5rem; }

.mr-18 {
  margin-right: 4.5rem; }

.ml-18 {
  margin-left: 4.5rem; }

.p-18 {
  padding: 4.5rem; }

.pv-18 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem; }

.ph-18 {
  padding-left: 4.5rem;
  padding-right: 4.5rem; }

.pt-18 {
  padding-top: 4.5rem; }

.pb-18 {
  padding-bottom: 4.5rem; }

.pr-18 {
  padding-right: 4.5rem; }

.pl-18 {
  padding-left: 4.5rem; }

.m-19 {
  margin: 4.75rem; }

.mv-19 {
  margin-top: 4.75rem;
  margin-bottom: 4.75rem; }

.mh-19 {
  margin-left: 4.75rem;
  margin-right: 4.75rem; }

.mt-19 {
  margin-top: 4.75rem; }

.mb-19 {
  margin-bottom: 4.75rem; }

.mr-19 {
  margin-right: 4.75rem; }

.ml-19 {
  margin-left: 4.75rem; }

.p-19 {
  padding: 4.75rem; }

.pv-19 {
  padding-top: 4.75rem;
  padding-bottom: 4.75rem; }

.ph-19 {
  padding-left: 4.75rem;
  padding-right: 4.75rem; }

.pt-19 {
  padding-top: 4.75rem; }

.pb-19 {
  padding-bottom: 4.75rem; }

.pr-19 {
  padding-right: 4.75rem; }

.pl-19 {
  padding-left: 4.75rem; }

.m-20 {
  margin: 5rem; }

.mv-20 {
  margin-top: 5rem;
  margin-bottom: 5rem; }

.mh-20 {
  margin-left: 5rem;
  margin-right: 5rem; }

.mt-20 {
  margin-top: 5rem; }

.mb-20 {
  margin-bottom: 5rem; }

.mr-20 {
  margin-right: 5rem; }

.ml-20 {
  margin-left: 5rem; }

.p-20 {
  padding: 5rem; }

.pv-20 {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.ph-20 {
  padding-left: 5rem;
  padding-right: 5rem; }

.pt-20 {
  padding-top: 5rem; }

.pb-20 {
  padding-bottom: 5rem; }

.pr-20 {
  padding-right: 5rem; }

.pl-20 {
  padding-left: 5rem; }

@media only screen and (max-width: 800px) {
  .mq__flex-wrap {
    flex-wrap: wrap; }
  .mq__flex-align-center {
    align-items: center; }
  .mq__flex-col {
    flex-direction: column; }
  .mq__m-0 {
    margin: 0; }
  .mq__m_0 {
    margin: 0rem; }
  .mq__mv-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .mq__mh-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mq__mt-0 {
    margin-top: 0rem; }
  .mq__mb-0 {
    margin-bottom: 0rem; }
  .mq__mr-0 {
    margin-right: 0rem; }
  .mq__ml-0 {
    margin-left: 0rem; }
  .mq__p-0 {
    padding: 0rem; }
  .mq__pv-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .mq__ph-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .mq__pt-0 {
    padding-top: 0rem; }
  .mq__pb-0 {
    padding-bottom: 0rem; }
  .mq__pr-0 {
    padding-right: 0rem; }
  .mq__pl-0 {
    padding-left: 0rem; }
  .mq__m_1 {
    margin: 0.25rem; }
  .mq__mv-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .mq__mh-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mq__mt-1 {
    margin-top: 0.25rem; }
  .mq__mb-1 {
    margin-bottom: 0.25rem; }
  .mq__mr-1 {
    margin-right: 0.25rem; }
  .mq__ml-1 {
    margin-left: 0.25rem; }
  .mq__p-1 {
    padding: 0.25rem; }
  .mq__pv-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .mq__ph-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .mq__pt-1 {
    padding-top: 0.25rem; }
  .mq__pb-1 {
    padding-bottom: 0.25rem; }
  .mq__pr-1 {
    padding-right: 0.25rem; }
  .mq__pl-1 {
    padding-left: 0.25rem; }
  .mq__m_2 {
    margin: 0.5rem; }
  .mq__mv-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .mq__mh-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mq__mt-2 {
    margin-top: 0.5rem; }
  .mq__mb-2 {
    margin-bottom: 0.5rem; }
  .mq__mr-2 {
    margin-right: 0.5rem; }
  .mq__ml-2 {
    margin-left: 0.5rem; }
  .mq__p-2 {
    padding: 0.5rem; }
  .mq__pv-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .mq__ph-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .mq__pt-2 {
    padding-top: 0.5rem; }
  .mq__pb-2 {
    padding-bottom: 0.5rem; }
  .mq__pr-2 {
    padding-right: 0.5rem; }
  .mq__pl-2 {
    padding-left: 0.5rem; }
  .mq__m_3 {
    margin: 0.75rem; }
  .mq__mv-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .mq__mh-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mq__mt-3 {
    margin-top: 0.75rem; }
  .mq__mb-3 {
    margin-bottom: 0.75rem; }
  .mq__mr-3 {
    margin-right: 0.75rem; }
  .mq__ml-3 {
    margin-left: 0.75rem; }
  .mq__p-3 {
    padding: 0.75rem; }
  .mq__pv-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .mq__ph-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .mq__pt-3 {
    padding-top: 0.75rem; }
  .mq__pb-3 {
    padding-bottom: 0.75rem; }
  .mq__pr-3 {
    padding-right: 0.75rem; }
  .mq__pl-3 {
    padding-left: 0.75rem; }
  .mq__m_4 {
    margin: 1rem; }
  .mq__mv-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .mq__mh-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mq__mt-4 {
    margin-top: 1rem; }
  .mq__mb-4 {
    margin-bottom: 1rem; }
  .mq__mr-4 {
    margin-right: 1rem; }
  .mq__ml-4 {
    margin-left: 1rem; }
  .mq__p-4 {
    padding: 1rem; }
  .mq__pv-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .mq__ph-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .mq__pt-4 {
    padding-top: 1rem; }
  .mq__pb-4 {
    padding-bottom: 1rem; }
  .mq__pr-4 {
    padding-right: 1rem; }
  .mq__pl-4 {
    padding-left: 1rem; }
  .mq__m_5 {
    margin: 1.25rem; }
  .mq__mv-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .mq__mh-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mq__mt-5 {
    margin-top: 1.25rem; }
  .mq__mb-5 {
    margin-bottom: 1.25rem; }
  .mq__mr-5 {
    margin-right: 1.25rem; }
  .mq__ml-5 {
    margin-left: 1.25rem; }
  .mq__p-5 {
    padding: 1.25rem; }
  .mq__pv-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .mq__ph-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .mq__pt-5 {
    padding-top: 1.25rem; }
  .mq__pb-5 {
    padding-bottom: 1.25rem; }
  .mq__pr-5 {
    padding-right: 1.25rem; }
  .mq__pl-5 {
    padding-left: 1.25rem; }
  .mq__m_6 {
    margin: 1.5rem; }
  .mq__mv-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .mq__mh-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mq__mt-6 {
    margin-top: 1.5rem; }
  .mq__mb-6 {
    margin-bottom: 1.5rem; }
  .mq__mr-6 {
    margin-right: 1.5rem; }
  .mq__ml-6 {
    margin-left: 1.5rem; }
  .mq__p-6 {
    padding: 1.5rem; }
  .mq__pv-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .mq__ph-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .mq__pt-6 {
    padding-top: 1.5rem; }
  .mq__pb-6 {
    padding-bottom: 1.5rem; }
  .mq__pr-6 {
    padding-right: 1.5rem; }
  .mq__pl-6 {
    padding-left: 1.5rem; }
  .mq__m_7 {
    margin: 1.75rem; }
  .mq__mv-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .mq__mh-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mq__mt-7 {
    margin-top: 1.75rem; }
  .mq__mb-7 {
    margin-bottom: 1.75rem; }
  .mq__mr-7 {
    margin-right: 1.75rem; }
  .mq__ml-7 {
    margin-left: 1.75rem; }
  .mq__p-7 {
    padding: 1.75rem; }
  .mq__pv-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .mq__ph-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .mq__pt-7 {
    padding-top: 1.75rem; }
  .mq__pb-7 {
    padding-bottom: 1.75rem; }
  .mq__pr-7 {
    padding-right: 1.75rem; }
  .mq__pl-7 {
    padding-left: 1.75rem; }
  .mq__m_8 {
    margin: 2rem; }
  .mq__mv-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .mq__mh-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mq__mt-8 {
    margin-top: 2rem; }
  .mq__mb-8 {
    margin-bottom: 2rem; }
  .mq__mr-8 {
    margin-right: 2rem; }
  .mq__ml-8 {
    margin-left: 2rem; }
  .mq__p-8 {
    padding: 2rem; }
  .mq__pv-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .mq__ph-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .mq__pt-8 {
    padding-top: 2rem; }
  .mq__pb-8 {
    padding-bottom: 2rem; }
  .mq__pr-8 {
    padding-right: 2rem; }
  .mq__pl-8 {
    padding-left: 2rem; }
  .mq__m_9 {
    margin: 2.25rem; }
  .mq__mv-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .mq__mh-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mq__mt-9 {
    margin-top: 2.25rem; }
  .mq__mb-9 {
    margin-bottom: 2.25rem; }
  .mq__mr-9 {
    margin-right: 2.25rem; }
  .mq__ml-9 {
    margin-left: 2.25rem; }
  .mq__p-9 {
    padding: 2.25rem; }
  .mq__pv-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .mq__ph-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .mq__pt-9 {
    padding-top: 2.25rem; }
  .mq__pb-9 {
    padding-bottom: 2.25rem; }
  .mq__pr-9 {
    padding-right: 2.25rem; }
  .mq__pl-9 {
    padding-left: 2.25rem; }
  .mq__m_10 {
    margin: 2.5rem; }
  .mq__mv-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .mq__mh-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mq__mt-10 {
    margin-top: 2.5rem; }
  .mq__mb-10 {
    margin-bottom: 2.5rem; }
  .mq__mr-10 {
    margin-right: 2.5rem; }
  .mq__ml-10 {
    margin-left: 2.5rem; }
  .mq__p-10 {
    padding: 2.5rem; }
  .mq__pv-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .mq__ph-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .mq__pt-10 {
    padding-top: 2.5rem; }
  .mq__pb-10 {
    padding-bottom: 2.5rem; }
  .mq__pr-10 {
    padding-right: 2.5rem; }
  .mq__pl-10 {
    padding-left: 2.5rem; }
  .mq__m_11 {
    margin: 2.75rem; }
  .mq__mv-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .mq__mh-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mq__mt-11 {
    margin-top: 2.75rem; }
  .mq__mb-11 {
    margin-bottom: 2.75rem; }
  .mq__mr-11 {
    margin-right: 2.75rem; }
  .mq__ml-11 {
    margin-left: 2.75rem; }
  .mq__p-11 {
    padding: 2.75rem; }
  .mq__pv-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .mq__ph-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .mq__pt-11 {
    padding-top: 2.75rem; }
  .mq__pb-11 {
    padding-bottom: 2.75rem; }
  .mq__pr-11 {
    padding-right: 2.75rem; }
  .mq__pl-11 {
    padding-left: 2.75rem; }
  .mq__m_12 {
    margin: 3rem; }
  .mq__mv-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .mq__mh-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mq__mt-12 {
    margin-top: 3rem; }
  .mq__mb-12 {
    margin-bottom: 3rem; }
  .mq__mr-12 {
    margin-right: 3rem; }
  .mq__ml-12 {
    margin-left: 3rem; }
  .mq__p-12 {
    padding: 3rem; }
  .mq__pv-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .mq__ph-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .mq__pt-12 {
    padding-top: 3rem; }
  .mq__pb-12 {
    padding-bottom: 3rem; }
  .mq__pr-12 {
    padding-right: 3rem; }
  .mq__pl-12 {
    padding-left: 3rem; }
  .mq__m_13 {
    margin: 3.25rem; }
  .mq__mv-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .mq__mh-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mq__mt-13 {
    margin-top: 3.25rem; }
  .mq__mb-13 {
    margin-bottom: 3.25rem; }
  .mq__mr-13 {
    margin-right: 3.25rem; }
  .mq__ml-13 {
    margin-left: 3.25rem; }
  .mq__p-13 {
    padding: 3.25rem; }
  .mq__pv-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .mq__ph-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .mq__pt-13 {
    padding-top: 3.25rem; }
  .mq__pb-13 {
    padding-bottom: 3.25rem; }
  .mq__pr-13 {
    padding-right: 3.25rem; }
  .mq__pl-13 {
    padding-left: 3.25rem; }
  .mq__m_14 {
    margin: 3.5rem; }
  .mq__mv-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .mq__mh-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mq__mt-14 {
    margin-top: 3.5rem; }
  .mq__mb-14 {
    margin-bottom: 3.5rem; }
  .mq__mr-14 {
    margin-right: 3.5rem; }
  .mq__ml-14 {
    margin-left: 3.5rem; }
  .mq__p-14 {
    padding: 3.5rem; }
  .mq__pv-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .mq__ph-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .mq__pt-14 {
    padding-top: 3.5rem; }
  .mq__pb-14 {
    padding-bottom: 3.5rem; }
  .mq__pr-14 {
    padding-right: 3.5rem; }
  .mq__pl-14 {
    padding-left: 3.5rem; }
  .mq__m_15 {
    margin: 3.75rem; }
  .mq__mv-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .mq__mh-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mq__mt-15 {
    margin-top: 3.75rem; }
  .mq__mb-15 {
    margin-bottom: 3.75rem; }
  .mq__mr-15 {
    margin-right: 3.75rem; }
  .mq__ml-15 {
    margin-left: 3.75rem; }
  .mq__p-15 {
    padding: 3.75rem; }
  .mq__pv-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .mq__ph-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .mq__pt-15 {
    padding-top: 3.75rem; }
  .mq__pb-15 {
    padding-bottom: 3.75rem; }
  .mq__pr-15 {
    padding-right: 3.75rem; }
  .mq__pl-15 {
    padding-left: 3.75rem; }
  .mq__m_16 {
    margin: 4rem; }
  .mq__mv-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .mq__mh-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .mq__mt-16 {
    margin-top: 4rem; }
  .mq__mb-16 {
    margin-bottom: 4rem; }
  .mq__mr-16 {
    margin-right: 4rem; }
  .mq__ml-16 {
    margin-left: 4rem; }
  .mq__p-16 {
    padding: 4rem; }
  .mq__pv-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .mq__ph-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .mq__pt-16 {
    padding-top: 4rem; }
  .mq__pb-16 {
    padding-bottom: 4rem; }
  .mq__pr-16 {
    padding-right: 4rem; }
  .mq__pl-16 {
    padding-left: 4rem; }
  .mq__m_17 {
    margin: 4.25rem; }
  .mq__mv-17 {
    margin-top: 4.25rem;
    margin-bottom: 4.25rem; }
  .mq__mh-17 {
    margin-left: 4.25rem;
    margin-right: 4.25rem; }
  .mq__mt-17 {
    margin-top: 4.25rem; }
  .mq__mb-17 {
    margin-bottom: 4.25rem; }
  .mq__mr-17 {
    margin-right: 4.25rem; }
  .mq__ml-17 {
    margin-left: 4.25rem; }
  .mq__p-17 {
    padding: 4.25rem; }
  .mq__pv-17 {
    padding-top: 4.25rem;
    padding-bottom: 4.25rem; }
  .mq__ph-17 {
    padding-left: 4.25rem;
    padding-right: 4.25rem; }
  .mq__pt-17 {
    padding-top: 4.25rem; }
  .mq__pb-17 {
    padding-bottom: 4.25rem; }
  .mq__pr-17 {
    padding-right: 4.25rem; }
  .mq__pl-17 {
    padding-left: 4.25rem; }
  .mq__m_18 {
    margin: 4.5rem; }
  .mq__mv-18 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem; }
  .mq__mh-18 {
    margin-left: 4.5rem;
    margin-right: 4.5rem; }
  .mq__mt-18 {
    margin-top: 4.5rem; }
  .mq__mb-18 {
    margin-bottom: 4.5rem; }
  .mq__mr-18 {
    margin-right: 4.5rem; }
  .mq__ml-18 {
    margin-left: 4.5rem; }
  .mq__p-18 {
    padding: 4.5rem; }
  .mq__pv-18 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem; }
  .mq__ph-18 {
    padding-left: 4.5rem;
    padding-right: 4.5rem; }
  .mq__pt-18 {
    padding-top: 4.5rem; }
  .mq__pb-18 {
    padding-bottom: 4.5rem; }
  .mq__pr-18 {
    padding-right: 4.5rem; }
  .mq__pl-18 {
    padding-left: 4.5rem; }
  .mq__m_19 {
    margin: 4.75rem; }
  .mq__mv-19 {
    margin-top: 4.75rem;
    margin-bottom: 4.75rem; }
  .mq__mh-19 {
    margin-left: 4.75rem;
    margin-right: 4.75rem; }
  .mq__mt-19 {
    margin-top: 4.75rem; }
  .mq__mb-19 {
    margin-bottom: 4.75rem; }
  .mq__mr-19 {
    margin-right: 4.75rem; }
  .mq__ml-19 {
    margin-left: 4.75rem; }
  .mq__p-19 {
    padding: 4.75rem; }
  .mq__pv-19 {
    padding-top: 4.75rem;
    padding-bottom: 4.75rem; }
  .mq__ph-19 {
    padding-left: 4.75rem;
    padding-right: 4.75rem; }
  .mq__pt-19 {
    padding-top: 4.75rem; }
  .mq__pb-19 {
    padding-bottom: 4.75rem; }
  .mq__pr-19 {
    padding-right: 4.75rem; }
  .mq__pl-19 {
    padding-left: 4.75rem; }
  .mq__m_20 {
    margin: 5rem; }
  .mq__mv-20 {
    margin-top: 5rem;
    margin-bottom: 5rem; }
  .mq__mh-20 {
    margin-left: 5rem;
    margin-right: 5rem; }
  .mq__mt-20 {
    margin-top: 5rem; }
  .mq__mb-20 {
    margin-bottom: 5rem; }
  .mq__mr-20 {
    margin-right: 5rem; }
  .mq__ml-20 {
    margin-left: 5rem; }
  .mq__p-20 {
    padding: 5rem; }
  .mq__pv-20 {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .mq__ph-20 {
    padding-left: 5rem;
    padding-right: 5rem; }
  .mq__pt-20 {
    padding-top: 5rem; }
  .mq__pb-20 {
    padding-bottom: 5rem; }
  .mq__pr-20 {
    padding-right: 5rem; }
  .mq__pl-20 {
    padding-left: 5rem; } }

