.NewsCardImg{
  width: 100%;
  max-height: 600px;
  object-fit: cover;
  cursor: pointer;
}

.iFrameButton{
  position: absolute;
  bottom: 24px;
  left: 50%;
  font-size: 14px;
  transform: translateX(-50%);
  box-shadow: 0px 8px 18px 0px rgba(0,0,0,.6);
  cursor: pointer;
}

.iFrameButton:hover{
  transform: scale(1.01) translateX(-50%);
  box-shadow: 0px 8px 20px 0px rgba(0,0,0,.6);
}