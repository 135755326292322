.Feed{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  text-align: center;
}

.FeedFooterImg{
  width: 96px;
  margin: auto;
  padding-top: 64px;
}