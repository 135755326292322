.CustomerCardHeader{
  display: flex;
  padding: 12px;
  padding-bottom: 0px;
  align-items: center;
  color: #FFEA3B;
  -webkit-text-fill-color: #FFEA3B;
}

.CustomerCardLogo{
  width: 48px;
  margin-right: 12px;
  display: inline-block;
}

.CustomerCardCalendarImg{
  height: 18px;
  margin: 4px;
  vertical-align: -30%;
}

.CustomerCardCaption{
  color: #fff;
  margin: 4px 0px 16px 0px;
}

.CustomerCardCustomer{
  position: relative;
}

.CustomerCardCustomer > img {
  width: 32px;
}

.CustomerCardCustomerOrders{
  position: absolute;
  right: 16px;
  top: 16px;
}

.CustomerCardCustomerCaption{
  position: absolute;
  right: 0px;
  top: 36px;
}