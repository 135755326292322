.Sidebar {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 270px;
  height: 100%;
  background: var(--p-blue);

  text-align: center;
  z-index: 13;
  overflow-y: auto;
}

.SidebarContent {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
  flex-direction: column;
  margin: 0 1.5rem;
}

.SidebarMobile {
  position: fixed;
  top: 8px;
  left: 8px;
  width: calc(100vw - 16px);
  background: #fff;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0px 4px 16px -2px rgba(0, 0, 0, 0.4);
  text-align: center;
  background: var(--p-blue);
}

.SidebarContainerMobile {
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.25rem;
}

.SidebarHNGR {
  font-size: 48px;
  font-weight: 900;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  color: #fff;
}

.SidebarHNGRMobile {
  font-size: 22px;
  padding: 8px;
  font-weight: 900;
  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  color: #fff;
  display: inline-block;
  vertical-align: top;
}

.SidebarLogo {
  width: 48%;
  max-width: 250px;
  margin: 16px;
}

.SidebarLogoMobile {
  height: 32px;
  margin: 8px;
  vertical-align: top;
  border-radius: 4px;
}

.SidebarPage {
  margin: 4px;
  font-size: 1.25rem;
  padding: 8px;
  text-align: left;
  /* width: 84%; */
  /* border-radius: 4px; */
  transition: all 0.2s ease;
  cursor: pointer;
  text-transform: capitalize;
}

.SidebarPageMobile {
  margin: 4px;
  font-size: 50px !important;
  padding: 8px;
  text-align: left;
  /* width: 84%; */
  /* border-radius: 4px; */
  transition: all 0.2s ease;
  cursor: pointer;
  text-transform: capitalize;

}

.SidebarPageActive {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.SidebarIcon {
  width: 32px;
}

.SidebarMenu {
  width: 32px;
}

.SidebarExpansion {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: grow-in 0.2s forwards ease;
}

.SidebarSelectedRest {
  height: 16px;
  margin: 0px 8px;
  vertical-align: middle;
}

@keyframes grow-in {
  from {
    transform: translateY(-50px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.sidebar-mobile__center {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
}

.switch-modal{
  width: calc(100vw - 32px);
  max-width: 600px;
  min-height: 100px;
  max-height: 70vh;
  background: #fff;
  border-radius: 8px;
  box-shadow: 2px 8px 48px -16px rgba(0,0,0,.4);
  animation: slide-up .5s ease forwards;
  -webkit-animation: slide-up .5s ease forwards;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: scroll;
  text-align:center;
}
