@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap");

:root {
  --p-blue: #01035e;
  --p-pink: #d62e70;
  --p-black: #000000;
  --p-grey: #cccccc;
}

* {
  box-sizing: border-box;
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

body {
  margin: 0;
  padding: 0;
}
/* 
body,
input,
button {
  font-family: "Nunito Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
} */

a {
  text-decoration: none;
}

input {
  background: 0;
  border: 0;
  font-size: 16px;
  border-bottom: 2px solid #ccc;
  transition: all 0.2s;
  width: calc(100% - 32px);
  margin: 8px;
  padding: 8px;
}

.no-ryan-input {
  background: initial;
  border: initial;
  border-bottom: initial;
  transition: initial;
  width: initial;
  margin: initial;
  padding: initial;
  font-size: 16px;
  max-width: initial;
  border-radius: initial;
}

input::placeholder {
  font-weight: 300;
  font-size: 0.8em;
  color: #aaa;
}

input:focus {
  border-bottom: 4px solid #4284f4;
  /* outline: none; */
}

select {
  /* outline: none; */
  font-size: 12px;
  font-weight: 500;
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 0;
  padding: 8px 12px;
}

textarea {
  background: #fff;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #222;
  padding: 16px;
  margin: 8px;
  width: calc(100% - 48px);
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.1);
}

.button {
  background: #4284f4;
  color: #fff;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 12px 24px;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 8px 16px -2px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
}

.button:hover {
  transform: scale(1.02);
  cursor: pointer;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.2);
}

iframe {
  border: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  border-collapse: collapse;
  box-sizing: border-box;
}

tr {
  transition: all 0.2s;
  box-sizing: border-box;
  border-bottom: 1px solid #e1e1e1;
  box-sizing: border-box;
}

tr:nth-child(even) {
  background: #fafafa;
}

tr:hover {
  background: rgba(66, 132, 244, 0.1);
  /* cursor: pointer; */
}

td {
  padding: 18px 24px;
}

thead {
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

th {
  padding: 8px;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  width: 100vw;
  /* overflow: hidden; */
  /* font-family: "Nunito Sans", sans-serif; */
  background: #dee8f3;
}

.fluid-content {
  max-width: 1440px;
}

.fluid-content--body {
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (min-width: 1441px) {
  .fluid-content {
    margin-left: auto;
    margin-right: auto;
  }
}

.AppBody {
  position: relative;
  top: 0px;
  margin-left: auto;
  width: calc(100vw - 270px);
  padding: 24px;
  text-align: center;
}

.AppBodyMobile {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 60px 16px 84px 16px;
  text-align: center;
  z-index: 0;
  animation: slide-in 0.5s forwards ease;
}

.inline-block {
  display: inline-block;
}

.secondary {
  background: rgb(0, 0, 0, 0);
  font-weight: 500;
  border: 3px solid #fff;
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  box-shadow: none;
  cursor: pointer;
}

.secondary:hover {
  box-shadow: none;
  transform: none;
  font-weight: 700;
  padding: 8px 10px;
}
.borderbox {
  box-sizing: border-box;
}

.w100 {
  width: 100%;
}

.w80 {
  width: 80%;
}

.w60 {
  width: 60%;
}

.w50 {
  width: 50%;
}

.w40 {
  width: 40%;
}

.w96px {
  width: 96px;
}

.w64px {
  width: 64px;
}

.w32px {
  width: 32px;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.p0 {
  padding: 0;
}

.ph4 {
  padding-left: 4px;
  padding-right: 4px;
}

.pv4 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.ph8 {
  padding-left: 8px;
  padding-right: 8px;
}

.pv8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.ph16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pv16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.ph32 {
  padding-left: 32px;
  padding-right: 32px;
}

.pv32 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.m0 {
  margin: 0;
}

.mh4 {
  margin-left: 4px;
  margin-right: 4px;
}

.mv4 {
  margin-top: 4px;
  margin-bottom: 4px;
}

.mh8 {
  margin-left: 8px;
  margin-right: 8px;
}

.mv8 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.mh16 {
  margin-left: 16px;
  margin-right: 16px;
}

.mv16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.fs10 {
  font-size: 10px;
}

.fs12 {
  font-size: 12px;
}

.fs16 {
  font-size: 16px;
}

.fs21 {
  font-size: 21px;
}

.fs24 {
  font-size: 24px;
}

.fs28 {
  font-size: 28px;
}

.fs32 {
  font-size: 32px;
}

.fs48 {
  font-size: 48px;
}

.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw500 {
  font-weight: 500;
}

.fw700 {
  font-weight: 700;
}

.fw900 {
  font-weight: 900;
}

.c222 {
  color: #222;
  -webkit-text-fill-color: #222;
}

.c999 {
  color: #999;
  -webkit-text-fill-color: #999;
}

.cbbb {
  color: #bbb;
  -webkit-text-fill-color: #bbb;
}

.cfff {
  color: #ffffff;
  -webkit-text-fill-color: #fff;
}

.cblue {
  color: #4284f4;
  -webkit-text-fill-color: #4284f4;
}

.bgfff {
  background: #fff;
}

.bgblue {
  background: #4284f4;
}

.cgreen {
  color: #34a853;
  -webkit-text-fill-color: #34a853;
}

.bggreen {
  background: #34a853;
}

.cred {
  color: #ea4335;
  -webkit-text-fill-color: #ea4335;
}

.bgred {
  background: #ea4335;
}

.textshadow {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.2);
}

.br4 {
  border-radius: 4px;
}

.br8 {
  border-radius: 8px;
}

.pointer {
  cursor: pointer;
}

.vatop {
  vertical-align: top;
}

.vamiddle {
  vertical-align: middle;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateX(100vw);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.datepicker {
  /* font-family: "Nunito Sans", sans-serif; */
}

.input--b-grey,
.input--b--l-grey {
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 0.5rem;
}

.input--b--l-grey {
  border: 1px solid #eee;
}

.input--b-grey::placeholder {
  font-size: 16px;
  color: #888888;
}

.input--b--l-grey::placeholder {
  color: #d0d0d0;
  font-size: 16px;
}

.no-ryan-input:focus {
  border-bottom: initial;
}

.border-b-pink {
  border-bottom: 1px solid var(--p-pink);
}

.svg-icon {
  width: 1em;
  height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
  fill: #4691f6;
}

.svg-icon circle {
  stroke: #4691f6;
  stroke-width: 1;
}

.input-no-outline:focus{
    outline-width: 0;
}

.odot-outer-container{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  width:100%;
}

.odot-container{
  width: 500px;
  height: 85vh;
  overflow: hidden;
}

.odot{
  width:200%;
  height:200%;
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.ReactModal__Overlay--after-open{
  z-index:999;
}
