.GrubcardHeader{
  display: flex;
  padding: 12px;
  padding-bottom: 0px;
  align-items: center;
}

.GrubcardLogo{
  width: 36px;
  margin-right: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,.2);
  display: inline-block;
}

.GrubcardCalendarImg{
  height: 18px;
  margin: 4px;
  vertical-align: -30%;
}

.GrubcardCaption{
  color: #fff;
  margin: 4px 0px 16px 0px;
}