@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,600,700,800,900&display=swap");
html {
  /* font-family: "Nunito Sans", sans-serif; */
}

.Login {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("/hngr_background.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

input {
  background: #fff;
  border-radius: 4px;
  padding: 8px;
  font-size: 18px;
  width: calc(100% - 48px);
  max-width: 300px;
  margin: 4px;
}

.login-button {
  margin-top: 16px;
  max-height: 48px;
  padding: 12px 24px;
  transition: all 0.2s;
}

.LoginGoogle {
  margin: 24px;
  /* font-family: "Nunito Sans", sans-serif !important; */
  background: #4284f4 !important;
  padding: 4px !important;
  margin: 4px !important;
  border: 0 !important;
  border-radius: 4px !important;
}

.LoginGoogle:hover {
  transform: scale(1.02);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.4);
}

.LoginGoogle div {
  border-radius: 4px !important;
  padding: 6px !important;
  height: 18px !important;
  width: 18px !important;
  margin: 0px 4px !important;
}

.LoginGoogle span {
  font-weight: 700 !important;
  margin: 0px 4px !important;
}

.Login h3 {
  color: 'white';
  font-weight: 'bold';
}
